// Generated by ts-to-zod
import { z } from "zod";

export type ManifestReference = z.infer<typeof ManifestReference>
export const ManifestReference = z.object({
  name: z.string(),
  package: z.string().optional(),
  module: z.string().optional(),
});

export type ManifestSourceReference = z.infer<typeof ManifestSourceReference>
export const ManifestSourceReference = z.object({
  href: z.string(),
});

export type ManifestSlot = z.infer<typeof ManifestSlot>
export const ManifestSlot = z.object({
  name: z.string(),
  summary: z.string().optional(),
  description: z.string().optional(),
  deprecated: z.union([z.boolean(), z.string()]).optional(),
});

export type ManifestCSSPart = z.infer<typeof ManifestCSSPart>
export const ManifestCSSPart = z.object({
  name: z.string(),
  summary: z.string().optional(),
  description: z.string().optional(),
  deprecated: z.union([z.boolean(), z.string()]).optional(),
});

export type ManifestCSSCustomProperty = z.infer<typeof ManifestCSSCustomProperty>
export const ManifestCSSCustomProperty = z.object({
  name: z.string(),
  syntax: z.string().optional(),
  default: z.string().optional(),
  summary: z.string().optional(),
  description: z.string().optional(),
  deprecated: z.union([z.boolean(), z.string()]).optional(),
});

export type ManifestDemo = z.infer<typeof ManifestDemo>
export const ManifestDemo = z.object({
  description: z.string().optional(),
  url: z.string(),
  source: ManifestSourceReference.optional(),
});

export type ManifestTypeReference = z.infer<typeof ManifestTypeReference>
export const ManifestTypeReference = ManifestReference.extend({
  start: z.number().optional(),
  end: z.number().optional(),
});

export type ManifestPrivacy = z.infer<typeof ManifestPrivacy>
export const ManifestPrivacy = z.union([
  z.literal("public"),
  z.literal("private"),
  z.literal("protected"),
]);

export type ManifestJavaScriptExport = z.infer<typeof ManifestJavaScriptExport>
export const ManifestJavaScriptExport = z.object({
  kind: z.literal("js"),
  name: z.string(),
  declaration: ManifestReference,
  deprecated: z.union([z.boolean(), z.string()]).optional(),
});

export type ManifestCustomElementExport = z.infer<typeof ManifestCustomElementExport>
export const ManifestCustomElementExport = z.object({
  kind: z.literal("custom-element-definition"),
  name: z.string(),
  declaration: ManifestReference,
  deprecated: z.union([z.boolean(), z.string()]).optional(),
});

export type ManifestType = z.infer<typeof ManifestType>
export const ManifestType = z.object({
  text: z.string(),
  References: z.array(ManifestTypeReference).optional(),
  source: ManifestSourceReference.optional(),
});

export type ManifestPropertyLike = z.infer<typeof ManifestPropertyLike>
export const ManifestPropertyLike = z.object({
  name: z.string(),
  summary: z.string().optional(),
  description: z.string().optional(),
  type: ManifestType.optional(),
  default: z.string().optional(),
  deprecated: z.union([z.boolean(), z.string()]).optional(),
  readonly: z.boolean().optional(),
});

export type ManifestParameter = z.infer<typeof ManifestParameter>
export const ManifestParameter = ManifestPropertyLike.extend({
  optional: z.boolean().optional(),
  rest: z.boolean().optional(),
});

export type ManifestExport = z.infer<typeof ManifestExport>
export const ManifestExport = z.union([
  ManifestJavaScriptExport,
  ManifestCustomElementExport,
]);

export type ManifestVariableDeclaration = z.infer<typeof ManifestVariableDeclaration>
export const ManifestVariableDeclaration = ManifestPropertyLike.extend({
  kind: z.literal("variable"),
  source: ManifestSourceReference.optional(),
});

export type ManifestAttribute = z.infer<typeof ManifestAttribute>
export const ManifestAttribute = z.object({
  name: z.string(),
  summary: z.string().optional(),
  description: z.string().optional(),
  inheritedFrom: ManifestReference.optional(),
  type: ManifestType.optional(),
  default: z.string().optional(),
  fieldName: z.string().optional(),
  deprecated: z.union([z.boolean(), z.string()]).optional(),
});

export type ManifestEvent = z.infer<typeof ManifestEvent>
export const ManifestEvent = z.object({
  name: z.string(),
  summary: z.string().optional(),
  description: z.string().optional(),
  type: ManifestType,
  inheritedFrom: ManifestReference.optional(),
  deprecated: z.union([z.boolean(), z.string()]).optional(),
});

export type ManifestClassField = z.infer<typeof ManifestClassField>
export const ManifestClassField = ManifestPropertyLike.extend({
  kind: z.literal("field"),
  static: z.boolean().optional(),
  privacy: ManifestPrivacy.optional(),
  inheritedFrom: ManifestReference.optional(),
  source: ManifestSourceReference.optional(),
});

export type ManifestCustomElementField = z.infer<typeof ManifestCustomElementField>
export const ManifestCustomElementField = ManifestClassField.extend({
  attribute: z.string().optional(),
  reflects: z.boolean().optional(),
});

export type ManifestFunctionLike = z.infer<typeof ManifestFunctionLike>
export const ManifestFunctionLike = z.object({
  name: z.string(),
  summary: z.string().optional(),
  description: z.string().optional(),
  deprecated: z.union([z.boolean(), z.string()]).optional(),
  parameters: z.array(ManifestParameter).optional(),
  return: z
    .object({
      type: ManifestType.optional(),
      summary: z.string().optional(),
      description: z.string().optional(),
    })
    .optional(),
});

export type ManifestFunctionDeclaration = z.infer<typeof ManifestFunctionDeclaration>
export const ManifestFunctionDeclaration = ManifestFunctionLike.extend({
  kind: z.literal("function"),
  source: ManifestSourceReference.optional(),
});

export type ManifestClassMethod = z.infer<typeof ManifestClassMethod>
export const ManifestClassMethod = ManifestFunctionLike.extend({
  kind: z.literal("method"),
  static: z.boolean().optional(),
  privacy: ManifestPrivacy.optional(),
  inheritedFrom: ManifestReference.optional(),
  source: ManifestSourceReference.optional(),
});

export type ManifestClassMember = z.infer<typeof ManifestClassMember>
export const ManifestClassMember = z.union([ManifestClassField, ManifestClassMethod]);

export type ManifestClassLike = z.infer<typeof ManifestClassLike>
export const ManifestClassLike = z.object({
  name: z.string(),
  summary: z.string().optional(),
  description: z.string().optional(),
  superclass: ManifestReference.optional(),
  mixins: z.array(ManifestReference).optional(),
  members: z.array(ManifestClassMember).optional(),
  source: ManifestSourceReference.optional(),
  deprecated: z.union([z.boolean(), z.string()]).optional(),
});

export type ManifestClassDeclaration = z.infer<typeof ManifestClassDeclaration>
export const ManifestClassDeclaration = ManifestClassLike.extend({
  kind: z.literal("class"),
});

export type ManifestMixinDeclaration = z.infer<typeof ManifestMixinDeclaration>
export const ManifestMixinDeclaration = ManifestClassLike
  .extend(ManifestFunctionLike.shape)
  .extend({
    kind: z.literal("mixin"),
  });

export type ManifestCustomElement = z.infer<typeof ManifestCustomElement>
export const ManifestCustomElement = ManifestClassLike.extend({
  tagName: z.string().optional(),
  attributes: z.array(ManifestAttribute).optional(),
  events: z.array(ManifestEvent).optional(),
  slots: z.array(ManifestSlot).optional(),
  cssParts: z.array(ManifestCSSPart).optional(),
  cssProperties: z.array(ManifestCSSCustomProperty).optional(),
  demos: z.array(ManifestDemo).optional(),
  customElement: z.literal(true),
});

export interface EditingConfig {
  group?: string,
  marks?: string[],
  inline?: boolean,
  selectable?: boolean,
  draggable?: boolean,
  code?: boolean,
  whitespace?: "pre" | "normal",
  definingAsContext?: boolean,
  definingForContent?: boolean,
  defining?: boolean,
  isolating?: boolean,
  /** The allowed content. Each key should be the name of the slot, and each value a [ProseMirror content expression](https://prosemirror.net/docs/guide/#schema.content_expressions). To allow zero or more flow elements, use the value `"flow*"` ([Flow content](https://developer.mozilla.org/en-US/docs/Web/HTML/Content_categories) is a broad category for most elements allowed in the `<body>`). To define the default (unnamed) slot, use the empty string `""` as the key. Set the property to `undefined` to disallow all content.*/
  content?: string | Record<string, string>,
}

export type ManifestCustomElementDeclaration = z.infer<typeof ManifestCustomElementDeclaration>
export const ManifestCustomElementDeclaration = ManifestClassDeclaration.extend(
  ManifestCustomElement.shape
);


export type ManifestWidgetDeclaration = z.infer<typeof ManifestWidgetDeclaration>
export const ManifestWidgetDeclaration = ManifestCustomElementDeclaration.and(z.object({
  group: z.string().optional(),
  marks: z.array(z.string()).optional(),
  inline: z.boolean().optional(),
  selectable: z.boolean().optional(),
  draggable: z.boolean().optional(),
  code: z.boolean().optional(),
  whitespace: z.enum(["pre", "normal"]).optional(),
  definingAsContext: z.boolean().optional(),
  definingForContent: z.boolean().optional(),
  defining: z.boolean().optional(),
  isolating: z.boolean().optional(),
  /** The allowed content. Each key should be the name of the slot, and each value a [ProseMirror content expression](https://prosemirror.net/docs/guide/#schema.content_expressions). To allow zero or more flow elements, use the value `"flow*"` ([Flow content](https://developer.mozilla.org/en-US/docs/Web/HTML/Content_categories) is a broad category for most elements allowed in the `<body>`). To define the default (unnamed) slot, use the empty string `""` as the key. Set the property to `undefined` to disallow all content. */
  content: z.string().or(z.record(z.string())).optional(),
}))


export type ManifestCustomElementMixinDeclaration = z.infer<typeof ManifestCustomElementMixinDeclaration>
export const ManifestCustomElementMixinDeclaration = ManifestMixinDeclaration.extend(
  ManifestCustomElement.shape
);

export type ManifestDeclaration = z.infer<typeof ManifestDeclaration>
export const ManifestDeclaration = z.union([
  ManifestCustomElementDeclaration,
  ManifestCustomElementMixinDeclaration,
  ManifestClassDeclaration,
  ManifestFunctionDeclaration,
  ManifestMixinDeclaration,
  ManifestVariableDeclaration
]);

export type ManifestJavaScriptModule = z.infer<typeof ManifestJavaScriptModule>
export const ManifestJavaScriptModule = z.object({
  kind: z.literal("javascript-module"),
  path: z.string(),
  summary: z.string().optional(),
  description: z.string().optional(),
  declarations: z.array(ManifestDeclaration).optional(),
  exports: z.array(ManifestExport).optional(),
  deprecated: z.union([z.boolean(), z.string()]).optional(),
});

export type ManifestModule = z.infer<typeof ManifestModule>
export const ManifestModule = ManifestJavaScriptModule;

export type CustomElementsManifest = z.infer<typeof CustomElementsManifest>
export const CustomElementsManifest = z.object({
  schemaVersion: z.string(),
  readme: z.string().optional(),
  modules: z.array(ManifestModule),
  deprecated: z.union([z.boolean(), z.string()]).optional(),
});