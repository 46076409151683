// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser, LocalTokenGroup} from "@lezer/lr"
export const parser = LRParser.deserialize({
  version: 14,
  states: "*zOVQPOOO!PQPO'#C^O!yOSO'#DWO#UOSO'#DWOOQO'#Ci'#CiO#aQPO'#DVO$ZQPO'#CjO$`QPO'#CkOOQO'#C{'#C{OVQPO'#CmO$eQPO'#CzOOQO'#Cl'#ClO$uQPO'#DcOOQO'#Co'#CoO$xQPO'#DcOOQO'#Cp'#CpO${QPO'#DcOOQO'#Cq'#CqOOQO'#Dc'#DcOOQO'#Cz'#CzQOQPOOOOQO'#C`'#C`OOQO'#Ca'#CaOOQO'#Cb'#CbO%WQQO'#CcO%]QPO'#CgOOQO'#C|'#C|OOQO,58x,58xOOOO'#Cr'#CrO&aOSO,59rOOQO,59r,59rO&lOSO,59rOOQO,59q,59qO&wQQO,59UO&|QPO,59VO'RQPO,59XO'cQPO,59XO'UQPO'#DdOOQO'#Cs'#CsO'hQPO,5:OOVQPO'#CtO'{QPO,5:ROVQPO'#CuO(^QPO,5:TOVQPO'#CvO(lQPO,5:VO(wQQO'#CdO)PQPO,58}O)UQQO,58}OOQO,59R,59ROOOO-E6p-E6pOOQO1G/^1G/^O)ZQPO1G.pO*TQPO1G.qO*}QPO1G.sOOQO-E6q-E6qO+zQPO,59`OOQO,59`,59`OOQO-E6r-E6rO,_QPO,59aO,rQPO,59aOOQO,59a,59aOOQO-E6s-E6sO-TQPO,59bO-hQPO,59bO-kQPO,59bOOQO,59b,59bOOQO-E6t-E6tOOQO1G.i1G.iO-yQQO1G.iOOQO7+$[7+$[OOQO7+$]7+$]OOQO7+$_7+$_OOQO'#Cf'#CfO.OQPO7+$TOOQO<<Go<<Go",
  stateData: ".T~OmOS~ORPO{QO!ORO!QSO!RUO!TVO!XXO~OqeOrfOsgOthOxiO~ORQXkQX{QX!OQX!QQX!RQX!TQX!XQX![QX!^QX!`QX!YQX~PnO[lO|lO}nO~O[lO|lO!PnO~ORyXkyX{yX!OyX!QyX!RyX!TyX!XyX![yX!^yX!`yX!YyX~PnORqO~ORrO~O![xO!^zO!`|OknX~PVO![xO!^zO!`|Ok!VX!Y!VX~Ou!OO~OqeOrfOsgOthORZXkZX{ZX!OZX!QZX!RZX!TZX!XZX![ZX!^ZX!`ZX!YZX~O[lO|lO}!TO~O[lO|lO!P!TO~O!S!UO~O!U!VO~O!Y!WO![xO!^zO!`|O~PVO!Y!WO~Ok!Wa![!Wa!^!Wa!`!Wa!Y!Wa~PVO![xOk!Za!^!Za!`!Za!Y!Za~O!^zOk!]a!`!]a!Y!]a~O!`|Ok!_a!Y!_a~OvWXwXX~Ov!fO~Ow!gO~OR^ik^i{^i!O^i!Q^i!R^i!T^i!X^i![^i!^^i!`^i!Y^i~PnOR_ik_i{_i!O_i!Q_i!R_i!T_i!X_i![_i!^_i!`_i!Y_i~PnOb!jORaikai{ai!Oai!Qai!Rai!Tai!Xai![ai!^ai!`ai!Yai~PnOkha![ha!^ha!`ha!Yha~PVO![xOkia!^ia!`ia!Yia~PVO![xOkia!^ia!`ia!Yia~O![xO!^zOkja!`ja!Yja~PVO![xO!^zOkja!`ja!Yja~Ou!kO~Ov!mO~O",
  goto: "'k!`PP!aP!o!o!o!o!y!|#P#SP#Z!a!a#i#vP$`$j$q$u%P%[%i%vPPP&R&U&nPPPPPPPP!a#ZPPPPPPPPPP&}'TPP'[P'bP'giWOXYsuwxz|!Y!]!aYjPT!U!V!WR!RiR!PhR!QhR!l!gZjPT!U!V!WiTOXYsuwxz|!Y!]!aS[OXQ!ZxQ!^zR!b|QYOQuX^vYsuw!Y!]!aQ!YxQ!]zR!a|S^OXQ!_zR!c|S`OXR!d|TbOXQmQQoRT!Smo[wYsu!Y!]!aR!Xw`yY[su!]!^!a!bR![y`{Y[^su!a!b!cR!`{[}Y[^`suR!e}RdOQYOQsX^vYsuw!Y!]!aQ!YxQ!]zR!a|QkPQpTQ!h!UQ!i!VR!j!WQcORtXZZOXxz|X]OXz|V_OX|TaOX",
  nodeNames: "⚠ ValueDefinition Literal Identifier ZeroOrMore OneOrMore ZeroOrOne Some Min Min Max OneOrMoreCommaSeparated String String PropertyReference DataType OrderedSequence Group Required UnorderedSequence Subset Alternation",
  maxTerm: 62,
  nodeProps: [
    ["group", -5,4,5,6,7,11,"Quantifier"]
  ],
  skippedNodes: [0],
  repeatNodeCount: 5,
  tokenData: "&h~RmXY!|YZ!|]^!|pq!|qr#Rrs#Wst#]vw#bwx#mxy#zyz#zz{$P{|$U|}$Z}!O$b!O!P#z!P!Q#z!Q![$v!^!_%^!`!a%k!a!b%p!c!}$b!}#O%u#P#Q%z#R#S$b#T#o$b#o#p&P#p#q&U#q#r&c~#ROm~~#WOb~~#]O!O~~#bOx~~#ePvw#h~#mO![~U#rP{Q!`!a#uS#zO!SSQ$PO!QQ~$UOq~~$ZOr~U$bOwS!QQQ$gTRQ}!O$b!Q![$b!c!}$b#R#S$b#T#o$bU$}TRQuS}!O$b!Q![$v!c!}$b#R#S$b#T#o$b~%cP!T~wx%f~%kO!R~~%pO!U~~%uOs~~%zO!X~~&PO!Y~~&UOt~~&ZP!`~#p#q&^~&cO!^~~&hOv~",
  tokenizers: [1, 2, new LocalTokenGroup("!Q~RRrs[wxa#O#Pf~aO!P~~fO}~~iRO;'Sr;'S;=`w;=`Or~wO|~~|P|~;=`<%lr~", 47, 12)],
  topRules: {"ValueDefinition":[0,1]},
  tokenPrec: 0
})
