// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
export const parser = LRParser.deserialize({
  version: 14,
  states: "%vOVQPOOO_QPO'#C^O|QPO'#CoO!SQPO'#CoOOQO'#Ci'#CiOVQPO'#ChOOQO'#Cg'#CgOOQO'#Co'#CoQOQPOOOOQO'#C`'#C`OOQO'#Ca'#CaOOQO'#Cb'#CbO![QPO'#CcOOQO'#Cp'#CpOOQO,58x,58xOVQPO'#CjO!aQPO,59fOOQO'#Ck'#CkO!lQPO,59dO!}QPO,59SO#TQPO,59SO#]QPO'#CzO#WQPO,59SO#hQPO'#CdO#pQPO,58}O#uQPO,58}O#zQPO,59UOOQO,59U,59UOOQO-E6h-E6hOOQO-E6i-E6iO$]QPO1G.nOOQO1G.i1G.iO$zQPO1G.iOOQO7+$Y7+$YOOQO'#Cf'#CfO%PQPO7+$TOOQO<<Go<<Go",
  stateData: "%U~ObOS~ORPOmTO~OeXOfYOgZOh[ORQX`QXmQXoQXpQX~ORPOmTOo_O`cX~OigO~Oo_O`napna~ORPOmTO`laolapla~ORPOmTOo_OpnO~ORPOmTOo_O~OjWXkXX~OjoO~OkpO~ORPOmTO`^ao^ap^a~OeXOfYOgZOh[OR[i`[im[io[ip[i~OirO~OjtO~O",
  goto: "#uoPPpP!Q!Q!Q!Q!U!X![!_!h!x#O#YPPP#c#fPPPPPPP#lP#qQQOYaQbcejQcTRj_T]PnRh[Ri[RspQROQdTRk_QQOYaQbcejQeTRj_QVORfTY`QRcdeRl`WbQcejRmbRWOQ^PRqnVUOT_TSOT",
  nodeNames: "⚠ ContentExpression SimpleExpression Identifier ZeroOrMore OneOrMore ZeroOrOne Some Min Min Max Sequence Group Alternation",
  maxTerm: 32,
  skippedNodes: [0],
  repeatNodeCount: 2,
  tokenData: "#r~RaXY!WYZ!W]^!Wpq!Wxy!]yz!bz{!g{|!l|}!q!Q![!v!a!b#O!c!}#T#R#S#T#T#o#T#o#p#c#p#q#h#q#r#m~!]Ob~~!bOm~~!gOp~~!lOe~~!qOf~~!vOk~~!{Pi~!Q![!v~#TOg~~#YRR~!c!}#T#R#S#T#T#o#T~#hOh~~#mOo~~#rOj~",
  tokenizers: [0],
  topRules: {"ContentExpression":[0,1]},
  tokenPrec: 0
})
